import Header from "../components/admin/Header";
import Table from "../components/admin/Table";
import { AuthContext } from "../context/AuthContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

const PANEL = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/iniciar-sesion");
  }

  return (
    <>
      <Helmet>
        <title>Inicio | Limoncello</title>
      </Helmet>
      {isLoggedIn ? (
        <div>
          <Header />
          <Table />
        </div>
      ) : (
        <p>No has iniciado sesión.</p>
      )}
    </>
  );
};

export default PANEL;
