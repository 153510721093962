import Header from "../components/client/Header";
import Form from "../components/client/Form";
import { Helmet } from "react-helmet";

const Schedule_Reservation = () => {
  return (
    <>
      <Helmet>
        <title>Agendar Reserva | Limoncello</title>
      </Helmet>
      <Header />
      <Form />
    </>
  );
};
export default Schedule_Reservation;
