// Icons
import {
  UsersIcon,
  PhoneArrowUpRightIcon,
  AtSymbolIcon,
  PencilSquareIcon,
  BuildingStorefrontIcon,
  SparklesIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { TrashIcon, CakeIcon } from "@heroicons/react/24/outline";

// Api
import {
  deleteReserve,
  getReserves,
  updateStatus,
} from "../../api/reserve.api";

// Components
import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

// DatePicker
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

// idioma español
registerLocale("es", es);

// Establece el idioma español como predeterminado
setDefaultLocale("es");

const campusOptions = [
  { value: "Viva", label: "Viva" },
  { value: "El_Trapiche", label: "El Trapiche" },
];

const statusOptions = [
  { value: "Activo", label: "Activo" },
  { value: "Inactivo", label: "Inactivo" },
  { value: "", label: "Mostrar Todos" },
];

const Table = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 12;
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // Establecer el primer elemento como opción seleccionada por defecto
  const [filterActive, setFilterActive] = useState(statusOptions[0]);

  const [selectedDate, setSelectedDate] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getReserves(token)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
  
          // Primero ordenar por fecha
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA - dateB;
          } else {
            // Si las fechas son iguales, ordenar por hora
            const hourA = new Date(a.date + 'T' + a.hour).getTime();
            const hourB = new Date(b.date + 'T' + b.hour).getTime();
            return hourA - hourB;
          }
        });
        setTotalPages(Math.ceil(sortedData.length / itemsPerPage));
        setData(sortedData);
        setFilteredData(sortedData);
      })
      .catch((error) => {
        toast.error("Error, las reservas no se han cargado.", {
          position: "top-right",
        });
        console.log(error);
      });
  }, []);

  const handleDelete = (id) => {
    deleteReserve(id, token)
      .then(() => {
        toast.success("Reserva eliminada con éxito", {
          position: "top-right",
        });
        setData(data.filter((item) => item.id !== id));
        setFilteredData(filteredData.filter((item) => item.id !== id));
      })
      .catch((error) => {
        toast.error("Error, la reserva no ha sido eliminada", {
          position: "top-right",
        });
        console.error(`Error al eliminar reserva con ID ${id}:`, error);
      });
  };

  const handleStatus = () => {
    updateStatus(token)
      .then(() => {
        toast.success("Reservas actualizadas", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.href = "/panel";
        }, 2000);
      })
      .catch((error) => {
        toast.error("Ups, parece que hubo un error", {
          position: "top-center",
        });
        console.error(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCampusChange = (selectedOption) => {
    setSelectedCampus(selectedOption);
  };

  const handleFilterChange = (selectedOption) => {
    setFilterActive(selectedOption);
  };

  useEffect(() => {
    const filteredByCampus = selectedCampus
      ? data.filter((item) => item.campus === selectedCampus.value)
      : data;

    const filteredByStatus =
      filterActive && filterActive.value !== ""
        ? filteredByCampus.filter((item) => item.status === filterActive.value)
        : filteredByCampus;

    const filteredByDate = selectedDate
      ? filteredByStatus.filter(
          (item) => item.date === selectedDate.toISOString().split("T")[0]
        )
      : filteredByStatus;

    const sortedData = filteredByDate.sort(
      (a, b) => new Date(a.hour) - new Date(b.hour)
    );

    const results = sortedData.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    setFilteredData(results);
    setTotalPages(Math.ceil(results.length / itemsPerPage));
    setCurrentPage(1);
  }, [data, selectedCampus, searchTerm, filterActive, selectedDate]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <div className="sm:flex sm:flex-col min-h-screen bg-zinc-50 dark:bg-black-bg">
        <div className="mx-auto max-w-screen-xl px-4 sm:py-8 sm:px-6 lg:px-8 xl:max-w-full xsp:pt-1 xsp:pb-2">
          <Select
            options={campusOptions}
            value={selectedCampus}
            onChange={handleCampusChange}
            className="sm:w-auto max-w-[14rem] px-3 py-2 mb-3 border text-gray-600 font-semibold dark:text-gray-200 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-offset-gray-900 dark:focus:ring-blue-500 inline-flex mr-5 font-WinterPoppins xss:w-60 justify-center"
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "var(--color-blue-500)"
                  : state.isFocused
                  ? "var(--color-blue-100)"
                  : "transparent",
                color: state.isSelected ? "#fff" : "inherit",
                "&:hover": {
                  backgroundColor: state.isSelected
                    ? "var(--color-blue-500)"
                    : "var(--color-blue-100)",
                  color: state.isSelected ? "#fff" : "inherit",
                },
                backdropFilter: "blur(5px)", // agrega el efecto de desenfoque
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "var(--color-gray-100)",
                borderColor: "var(--color-gray-300)",
                "&:hover": {
                  borderColor: state.isFocused
                    ? "var(--color-blue-500)"
                    : "var(--color-gray-400)",
                },
                "&:active": {
                  backgroundColor: "var(--color-gray-200)",
                },
                boxShadow: state.isFocused
                  ? "0 0 0 2px var(--color-blue-500)"
                  : "none",
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: "inherit",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "var(--color-blue-100)",
                primary: "var(--color-blue-500)",
                neutral0: "var(--color-gray-100)",
                neutral50: "var(--color-gray-300)",
                neutral80: "var(--color-gray-700)",
              },
            })}
            placeholder="Filtrar Sede"
            isSearchable={false}
            aria-label="Filtrar Sede"
          />
          <Select
            options={statusOptions}
            value={filterActive}
            onChange={handleFilterChange}
            className="sm:w-auto max-w-[14rem] px-3 py-2 mb-3 border text-gray-600 font-semibold dark:text-gray-200 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-offset-gray-900 dark:focus:ring-blue-500 inline-flex mr-5 font-WinterPoppins xss:w-60 justify-center"
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "var(--color-blue-500)"
                  : state.isFocused
                  ? "var(--color-blue-100)"
                  : "transparent",
                color: state.isSelected ? "#fff" : "inherit",
                "&:hover": {
                  backgroundColor: state.isSelected
                    ? "var(--color-blue-500)"
                    : "var(--color-blue-100)",
                  color: state.isSelected ? "#fff" : "inherit",
                },
                backdropFilter: "blur(5px)",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "var(--color-gray-100)",
                borderColor: "var(--color-gray-300)",
                "&:hover": {
                  borderColor: state.isFocused
                    ? "var(--color-blue-500)"
                    : "var(--color-gray-400)",
                },
                "&:active": {
                  backgroundColor: "var(--color-gray-200)",
                },
                boxShadow: state.isFocused
                  ? "0 0 0 2px var(--color-blue-500)"
                  : "none",
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: "inherit",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "var(--color-blue-100)",
                primary: "var(--color-blue-500)",
                neutral0: "var(--color-gray-100)",
                neutral50: "var(--color-gray-300)",
                neutral80: "var(--color-gray-700)",
              },
            })}
            placeholder="Filtrar por estado"
            isSearchable={false}
            aria-label="Filtrar por estado"
          />

          <DatePicker
            selected={selectedDate}
            aria-label="Filtrar Fecha"
            placeholderText="Filtrar Fecha"
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
            type="date"
            className="w-40 pl-7 mr-3 my-2 rounded-xl bg-white border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-600 dark:focus:border-blue-500 dark:focus:ring-1 dark:focus:ring-blue-500 placeholder-gray-400 dark:placeholder-gray-200 font-WinterPoppins font-semibold cursor-pointer"
          />

          {selectedDate && (
            <button
              className="px-3 mr-2 py-2 text-sm font-medium leading-tight text-white bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:bg-blue-600 font-WinterPoppins"
              onClick={() => {
                setSelectedDate(null);
                setCurrentPage(1);
              }}
            >
              Limpiar filtro
            </button>
          )}

          <button
            className="px-3 mr-2 py-2 text-sm font-medium leading-tight text-green-200 bg-green-600 rounded-md shadow hover:bg-green-700 focus:outline-none focus:bg-green-700 font-WinterPoppins"
            onClick={() => handleStatus()}
          >
            Actualizar Estados
          </button>

          <div className="relative inline-block max-w-screen-2xl text-gray-400 dark:text-gray-500 font-WinterPoppins">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon className="w-5 h-5" />
                </div>
                <input
                  id="search"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-3 py-2 rounded-full bg-white border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-600 dark:focus:border-blue-500 dark:focus:ring-1 dark:focus:ring-blue-500"
                  placeholder="Buscar reserva..."
                />
              </div>
            </div>
          </div>

          <h1 className="xss:text-[1.4rem] xss:my-6 sm:text-2xl lg:text-2xl xl:text-2xl sm:my-4 text-gray-700 dark:text-gray-200 font-Rostley">
            Tus reservas
          </h1>

          <div className="overflow-auto rounded-lg shadow hidden lg:block max-w-full sm:max-w-full md:max-w-xl lg:max-w-4xl xl:max-w-full">
            <table className="max-w-full table-auto w-full md:w-auto lg:w-auto xl:w-full">
              <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-black-field dark:border-gray-800">
                <tr>
                  <th
                    title="Cantidad de personas"
                    className="w-5 p-3 text-sm font-semibold tracking-wide text-left dark:text-gray-200"
                  >
                    <UsersIcon className="text-blue-500 font-bold h-5 w-5" />
                  </th>

                  <th className="p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200 lg:pr-44 2xl:pr-0">
                    Descripción
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Estado
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Sede
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Nombre
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Apellido
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Fecha
                  </th>

                  <th className="w-18 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Hora
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Fecha de Creación
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Fecha Nacimiento
                  </th>

                  <th className="w-32 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Correo
                  </th>

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200">
                    Número Teléfono
                  </th>

                  {/* Section decoration and all_day */}

                  {/* <th
                    title="Decoraciones"
                    className="w-15 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200"
                  >
                    <SparklesIcon className="w-5 h-5  dark:text-gray-200" />
                  </th>

                  <th
                    title="Reserva día completo"
                    className="w-15 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200"
                  >
                    <BuildingStorefrontIcon className="w-5 h-5  dark:text-gray-200" />
                  </th> */}

                  <th className="w-24 p-3 text-sm font-WinterPoppins tracking-wide text-left text-gray-600 dark:text-gray-200"></th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-100 dark:divide-gray-900">
                {currentPageData.map((item, index) => (
                  <tr
                    key={item.id}
                    className={`${
                      index % 2 === 0
                        ? "bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-950 cursor-pointer"
                        : "bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-950 cursor-pointer"
                    }`}
                  >
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <b className="font-bold text-blue-500 font-WinterPoppins">
                        {item.num_person}
                      </b>
                    </td>

                    <td className="p-3 text-sm text-gray-700 dark:text-gray-200 lg:max-w-[300px] max-h-[20px] overflow-x-auto whitespace-normal break-words">
                      {item.description}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200">
                      <span
                        className={`p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg font-WinterPoppins bg-opacity-50 ${
                          item.status.toLowerCase() === "activo"
                            ? "text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-800"
                            : "dark:text-red-200 dark:bg-red-800 text-red-800 bg-red-200"
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.campus}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.name.toUpperCase().charAt(0) +
                        item.name.toLowerCase().slice(1)}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.last_name.toUpperCase().charAt(0) +
                        item.last_name.toLowerCase().slice(1)}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.date}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.hour}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.created_at}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.birthday_date}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.email}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.telephone_number}
                    </td>

                    {/* Section decoration and all_day */}

                    {/* <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.decoration ? "Sí" : "No"}
                    </td>

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap dark:text-gray-200 font-WinterPoppins">
                      {item.all_day ? "Sí" : "No"}
                    </td> */}

                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap flex items-center space-x-2">
                      <Link to={`/editar-reserva/${item.id}`}>
                        <PencilSquareIcon
                          title="Editar reserva"
                          className="h-5 w-5 text-gray-80 dark:text-gray-200"
                        />
                      </Link>
                      <button
                        title="Eliminar reserva"
                        onClick={() => handleDelete(item.id)}
                      >
                        <TrashIcon className="h-5 w-5 text-red-600" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:hidden">
            {currentPageData.map((item) => (
              <div className="bg-white space-y-3 p-4 rounded-lg shadow dark:bg-gray-800 dark:shadow-lg dark:shadow-offset-x-2 dark:shadow-offset-y-4 dark:shadow-opacity-50 dark:shadow-color-blue-500 hover:bg-gray-100">
                <div className="flex items-center space-x-2 text-sm">
                  <UsersIcon className="text-blue-500 font-bold h-5 w-5" />
                  <div>
                    <b className="text-blue-500 font-bold">{item.num_person}</b>
                  </div>

                  <div className="text-blue-800 bg-blue-200 rounded-lg bg-opacity-50 p-1.5 tracking-wider text-xs font-medium dark:bg-blue-800 dark:text-blue-200">
                    {item.date}
                  </div>
                  <div className="text-blue-800 bg-blue-200 rounded-lg bg-opacity-50 p-1.5 tracking-wider text-xs font-medium dark:bg-blue-800 dark:text-blue-200">
                    {item.hour}
                  </div>

                  <div>
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider  rounded-lg bg-opacity-50 ${
                        item.status.toLowerCase() === "activo"
                          ? "text-green-800 bg-green-200 dark:bg-green-800 dark:text-green-200"
                          : "text-red-800 bg-red-200 dark:bg-red-800 dark:text-red-200"
                      }`}
                    >
                      {item.status}
                    </span>
                  </div>
                </div>

                <div className="flex items-center space-x-2 text-sm">
                  <div className="text-gray-500 dark:text-gray-200">
                    {item.name.toUpperCase().charAt(0) +
                      item.name.toLowerCase().slice(1)}
                  </div>
                  <div className="text-gray-500 dark:text-gray-200">
                    {item.last_name.toUpperCase().charAt(0) +
                      item.last_name.toLowerCase().slice(1)}
                  </div>

                  <div>
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50 dark:bg-yellow-800 dark:text-yellow-200">
                      {item.campus}
                    </span>
                  </div>
                </div>

                <div className="flex items-center space-x-2 text-sm">
                  <PhoneArrowUpRightIcon className="w-5 h-5 text-gray-600 dark:text-gray-50" />

                  <div className="text-gray-500 dark:text-gray-200">
                    {item.telephone_number}
                  </div>
                  <div className="text-blue-800 rounded-lg bg-opacity-50 p-1.5 tracking-wider text-xs font-medium dark:text-blue-400">
                    {item.created_at}
                  </div>
                </div>

                <div className="flex items-center space-x-2 text-sm">
                  <AtSymbolIcon className="w-5 h-5 dark:text-gray-50 " />

                  <div className="text-sm text-gray-700 dark:text-gray-200">
                    {item.email}
                  </div>
                </div>

                <div className="flex items-center space-x-2 text-sm">
                  <CakeIcon
                    title="Cumpleaños"
                    className="w-5 h-5  dark:text-red-300"
                  />

                  <div className="text-sm text-gray-700 dark:text-gray-200">
                    {item.birthday_date}
                  </div>
                </div>

                {/* Section decoration and all_day
                <div className="flex items-center space-x-2 text-sm">
                  <SparklesIcon
                    title="Decoraciones"
                    className="w-5 h-5  dark:text-yellow-200"
                  />

                  <div className="text-sm text-gray-700 dark:text-gray-200">
                    {item.decoration ? "Sí" : "No"}
                  </div>

                  <BuildingStorefrontIcon
                    title="Reserva dia completo"
                    className="w-5 h-5  dark:text-red-400"
                  />
                  <div className="text-sm text-gray-700 dark:text-gray-200">
                    {item.all_day ? "Sí" : "No"}
                  </div>
                </div> */}

                <div className="text-sm text-gray-700 dark:text-gray-200">
                  {item.description}
                </div>

                <hr className="my-4 border border-solid border-gray-600 border-opacity-50 dark:border-gray-400" />

                <div className="flex items-center justify-end space-x-2 text-sm">
                  <button className="bg-gray-200 rounded-3xl w-9 flex items-center justify-center dark:bg-gray-900">
                    <Link to={`/editar-reserva/${item.id}`}>
                      <PencilSquareIcon className="h-8 w-8 p-1 text-gray-800 dark:text-gray-200" />
                    </Link>
                  </button>

                  <button
                    onClick={() => handleDelete(item.id)}
                    className="bg-gray-200 rounded-3xl w-9 flex items-center justify-center dark:bg-gray-900"
                  >
                    <TrashIcon className="h-8 w-8 p-1 text-red-500" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
