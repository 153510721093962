import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { RiSendPlaneFill } from "react-icons/ri";
import { BiLoader } from "react-icons/bi";

const Form = () => {
  const [formData, setFormData] = useState({
    num_person: "",
    campus: "El_Trapiche",
    date: "",
    hour: "",
    description: "",
    name: "",
    last_name: "",
    email: "",
    telephone_number: "",
    status: "Activo",
    created_at: "2023-04-29 18:53",
    birthday_date: "",
    all_day: false,
    decoration: false,
  });

  const [isSending, setIsSending] = useState(false);

  const handleClick = async () => {
    setIsSending(true);
    try {
      await simularEnvioDatos();
      await enviarDatos();
    } catch (error) {
      console.error(error);
    }
    setIsSending(false);
  };

  const simularEnvioDatos = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(); // Simulación de éxito
      }, 1900);
    });
  };

  const enviarDatos = async () => {
    try {
      await axios.post(
        "https://limoncello.onrender.com/api/reservespost/",
        formData
      );
      toast.success(
        `Tu reserva ha sido creada con exito, te enviaremos un correo de confirmacion \n Te recordamos que dispones de 15 minutos después de la hora de tu reserva. Transcurrido este tiempo, la reserva será cancelada.`,
        {
          position: "top-right",
          duration: 5000,
          fontSize: "lg:text-2xl text-[1rem]",
        }
      );
      setTimeout(() => {
        window.location.href = "https://www.limoncello.com.co/"; // redirige a google.com después de 1.5 segundos
      }, 3000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errors = error.response.data;
          const errorMessages = {
            name: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            date: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            email: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            last_name: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            num_person: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            hour: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            telephone_number: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            birthday_date: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            non_field_errors: (message) =>
              toast.error(message.join("\n"), { position: "top-center" }),
            default: () =>
              toast.error("Parece que hubo un error, verifica tu información", {
                position: "top-center",
              }),
          };

          Object.entries(errors).forEach(([fieldName, messages]) => {
            const showError = errorMessages[fieldName] || errorMessages.default;
            showError(messages);
          });
        } else {
          toast.error("Parece que hubo un error, verifica tu información", {
            position: "top-right",
          });
        }
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const campusOptions = [{ value: "El_Trapiche", label: "El Trapiche" }];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name === "campus" ? "campus" : name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí puedes agregar la lógica adicional que necesites al enviar el formulario
  };

  return (
    <>
      <div className="sm:flex sm:flex-col h-full bg-zinc-50 dark:bg-black-bg">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8 dark:bg-black-bg">
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12 dark:border-gray-300/10">
                <h2 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-300">
                  Información Reserva
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                  Digite los datos de la reserva.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-1 sm:col-start-1">
                    <label
                      htmlFor="num_person"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Cantidad Personas<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="number"
                          name="num_person"
                          id="num_person"
                          value={formData.num_person}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor="campus"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Sede<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <select
                        name="campus"
                        id="campus"
                        value={formData.campus}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      >
                        {campusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                      pattern="[0-9]{10}"
                      required
                    >
                      Fecha<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        autoComplete="date-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      ></input>
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor="hour"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Hora<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="time"
                        id="hour"
                        name="hour"
                        value={formData.hour}
                        onChange={handleChange}
                        autoComplete="hour-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Observaciones
                      <span
                        class="text-sm text-gray-500 ml-1"
                        id="link-checkbox-help"
                      >
                        (Opcional)
                      </span>
                    </label>

                    <div className="mt-2">
                      <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                        defaultValue={""}
                      />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
                      Más información acerca de la reserva.
                    </p>
                    <p className="mt-3 text-sm leading-6 text-gray-800 dark:text-gray-100">
                    Por el momento, no ofrecemos el servicio de decoración, pero si lo prefieres, puedes encargarte tú mismo. Para obtener más información, contáctanos al siguiente número: 3115639536
                    </p>
                  </div>
                </div>

                {/* Checbox decoration and all_day */}
                {/* <div className="flex items-center mt-5">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    name="decoration"
                    checked={formData.decoration}
                    onChange={handleInputChange}
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-2 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-indigo-600 "
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Decoraciones para la reserva
                  </label>
                  <span
                    className="text-sm text-gray-500 ml-1"
                    id="link-checkbox-help"
                  >
                    (Opcional)
                  </span>
                </div>

                <div className="flex items-center mt-5">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    name="all_day"
                    checked={formData.all_day}
                    onChange={handleInputChange}
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-2 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-indigo-600 "
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Reservar día completo
                  </label>
                </div> */}
              </div>

              <div className="border-b border-gray-900/10 pb-12 dark:border-gray-300/10">
                <h2 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-300">
                  Información Personal
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                  Digite sus datos personales.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Nombre<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Apellido<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Correo Electrónico<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder=""
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                      pattern="[0-9]{10}"
                      required
                    >
                      Fecha de Nacimiento<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        id="birthday_date"
                        name="birthday_date"
                        value={formData.birthday_date}
                        onChange={handleChange}
                        autoComplete="date-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      ></input>
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="telephone_number"
                      className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300"
                    >
                      Telefono<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="telephone_number"
                        name="telephone_number"
                        type="tel"
                        placeholder=""
                        value={formData.telephone_number}
                        onChange={handleChange}
                        maxLength={10}
                        autoComplete="telephone_number"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-black-field dark:text-gray-300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <Link to={"https://www.limoncello.com.co/"}>
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300"
                >
                  Cancelar
                </button>
              </Link>
              <button
                type="submit"
                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 dark:bg-green-500 dark:text-black"
                onClick={handleClick}
                disabled={isSending}
              >
                {isSending ? (
                  <span className="flex items-center text-white">
                    Enviando datos...
                    <BiLoader className="h-6 w-6 text-green-200 ml-1 animate-pulse" />
                  </span>
                ) : (
                  <span className="flex items-center text-white">
                    Enviar
                    <RiSendPlaneFill className="h-6 w-6 text-green-200 ml-1 animate-pulse" />
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Form;
