import DarkMode from "../darkMode/DarkMode";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-hot-toast";
import "animate.css/animate.min.css";

const Header = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Sesión cerrada exitosamente", {
        position: "top-right",
      });

      setTimeout(() => {
        navigate("/");
        localStorage.removeItem("token");
      }, 2000); // Esperar 2 segundos antes de redirigir
    } catch (error) {
      toast.error("Algo ha salido mal", {
        position: "top-right",
      });
    }
  };

  return (
    <header
      aria-label="Page Header"
      className="bg-gradient-to-b from-[#affd49e3] via-[#b9ff5e75]  to-gray-50 dark:from-[#1e3544] dark:via-[#172f35]  dark:to-gray-900"
    >
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
        <div className="flex items-center sm:justify-end gap-4 h-25 xss:justify-center">
          <div className="flex items-center gap-4">
            <div className="relative"></div>
          </div>

          <DarkMode />

          <button
            title="Cerrar sesión"
            onClick={handleLogout}
            className="bg-gray-100 border shadow-lg rounded-3xl h-10 w-12 flex justify-center items-center dark:bg-gray-900 border-gray-200 dark:hover:border-gray-500 dark:border-gray-600"
          >
            <ArrowLeftOnRectangleIcon className="h-8 w-7 text-[#14b8a6]  dark:text-gray-500 dark:hover:text-gray-400" />
          </button>

          <span
            aria-hidden="true"
            className="block h-6 w-px rounded-full bg-zinc-200"
          ></span>

          <a href="/panel" className="block shrink-0" title="Inicio">
            <span className="sr-only">Profile</span>
            <img
              alt="Limoncello"
              src="/image/icon.png"
              className="h-16 w-16 rounded-full object-cover"
            />
          </a>
        </div>

        <div className="mt-8">
          <h1 className="xss:text-[2.5rem] sm:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] font-bold tracking-tight text-gray-600 dark:text-gray-100 font-Rostley animate__animated animate__backInLeft shadowed-text">
            Panel de Administración
          </h1>

          <p className="hidden xsp:block mt-1.5 text-[1.1rem] xss:text-[1rem] text-gray-600 dark:text-gray-200 font-WinterPoppins">
            🎊🎉Bienvenido. Aqui puedes editar, borrar y actualizar las reservas
            correspondientes. 🔎También puedes hacer busqueda de reservas.
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
