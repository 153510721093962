import DarkMode from "../darkMode/DarkMode";
import 'animate.css/animate.min.css';

const Header = () => {
  return (
    <>
      <header
        aria-label="Page Header"
        className="bg-gradient-to-b from-[#affd49e3] via-[#b9ff5e75]  to-gray-50 dark:from-[#1e3544] dark:via-[#172f35]  dark:to-gray-900"
      >
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="text-center sm:text-left">
              <h1 className="inline bg-gradient-to-r from-gray-600 via-gray-400 to-gray-500 bg-clip-text font-display text-5xl font-bold tracking-tight text-transparent dark:from-indigo-200 dark:via-sky-400 dark:to-indigo-200 font-WinterPoppins animate__animated animate__backInLeft ">
                Agenda tu reserva!
              </h1>

              <p class="mt-1.5 text-sm text-gray-500 dark:text-gray-200">
                Digita toda la información necesaria, Te esperamos...
              </p>
            </div>

            <div className="flex flex-col items-center sm:flex-row sm:justify-between sm:items-center">
              <div className="flex gap-4 items-center">
                <DarkMode />{" "}
                <span
                  aria-hidden="true"
                  className="block h-6 w-px rounded-full bg-zinc-200"
                ></span>
                <img
                  src="/image/limoncello_logo_IA.png"
                  alt="logo"
                  className="h-20 w-20 rounded-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
