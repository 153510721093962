import Header from "../components/admin/Header";
import Form from "../components/admin/Form";
import { Helmet } from "react-helmet";
import { AuthContext } from "../context/AuthContext";
import React, { useContext } from "react";

const EDIT_RESERVE = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <>
      <Helmet>
        <title>Editar Reserva | Limoncello</title>
      </Helmet>
      {isLoggedIn ? (
        <div>
          <Header />
          <Form />
        </div>
      ) : (
        <p>No has iniciado sesión.</p>
      )}
    </>
  );
};

export default EDIT_RESERVE;
