import React from "react";

import { Toaster } from "react-hot-toast";
import SIGN_IN from "../pages/SIGN_IN";
import PANEL from "../pages/PANEL";
import SCHEDULE_RESERVATION from "../pages/SCHEDULE_RESERVATION";
import PAGE_NOT_FOUND from "../pages/PAGE_NOT_FOUND";
import EDIT_RESERVE from "../pages/EDIT_RESERVE";

import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AppRoutes = () => {
  const { isLoggedIn } = useAuth();
  return (
    <>
      <Routes>
        <Route path="/iniciar-sesion" element={<SIGN_IN />} />
        <Route path="/agendar-reserva" element={<SCHEDULE_RESERVATION />} />
        <Route path="/editar-reserva/:id" element={<EDIT_RESERVE />} />
        <Route path="/panel" element={<PANEL />} />
        <Route path="/*" element={<PAGE_NOT_FOUND />} />

        <Route
          path="/"
          element={isLoggedIn ? <PANEL /> : <Navigate to="/iniciar-sesion" />}
        />
      </Routes>
      <Toaster />
    </>
  );
};
export default AppRoutes;
