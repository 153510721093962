import axios from "axios";

const reserveApi = axios.create({
  baseURL: "https://limoncello.onrender.com/api/",
});

export const getReserves = (token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return reserveApi.get("/reserves/", config);
};

export const getReserve = (id, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return reserveApi.get(`/reserves/${id}/`, config);
};

export const createReserve = (reserve) =>
  reserveApi.post("/reservespost/", reserve);

export const deleteReserve = (id, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return reserveApi.delete(`/reserves/${id}/`, config);
};

export const updateReserve = (id, reserve, token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return reserveApi.put(`/reserves/${id}/`, reserve, config);
};

export const updateStatus = (token) => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return reserveApi.get("/update-reserve/", config);
};